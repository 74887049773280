<script setup lang="ts">
import { SubscriptionType } from '@respell/database';
import BillingPricingCard, {
  type BillingCard,
} from '~/components/account/BillingPricingCard.vue';
import {
  stripeStarterMonthlyPriceId,
  stripeStarterYearlyPriceId,
  stripeTeamMonthlyPriceId,
  stripeTeamYearlyPriceId,
} from '~/util/constants';

const plans: BillingCard[] = computed(() => [
  {
    type: SubscriptionType.starter,
    description: 'For power users who need advanced automations',
    marketingTitle: 'Everything in Free, plus:',
    marketingItems: [
      '3 builder licenses',
      'Build spells with all steps and premium integrations (Instagram and LinkedIn)',
      'Build spells with our Research Agent and Bland Phone Calling Agent',
      'Use your team’s Knowledge Bases in spells with Data Sources',
    ],
    subscriptionPriceIds: {
      monthly: stripeStarterMonthlyPriceId,
      yearly: stripeStarterYearlyPriceId,
    },
    subscriptionPricePerMonth: {
      monthly: 19.99,
      yearly: 14.99,
    },
    isEnterprise: false,
    highlighted: false,
  },
  {
    type: SubscriptionType.team,
    description: 'For corporate teams building AI automations',
    marketingTitle: 'Everything in Starter, plus:',
    marketingItems: [
      'Unlimited builder licenses',
      'Build spells with all steps and premium integrations (Instagram and LinkedIn)',
      'Build spells with our Research Agent and Bland Call Agent',
      'Use your team’s Knowledge Bases in spells with Data Sources',
      'Respell Inbox - access to Human Review step to review your Agents’ work',
    ],
    subscriptionPriceIds: {
      monthly: stripeTeamMonthlyPriceId,
      yearly: stripeTeamYearlyPriceId,
    },
    subscriptionPricePerMonth: {
      monthly: 59.99,
      yearly: 44.99,
    },
    isEnterprise: false,
    highlighted: true,
  },
  {
    type: SubscriptionType.enterprise,
    description: 'For companies with high-volume workflows',
    isEnterprise: true,
    marketingTitle: 'Everything in Team, plus:',
    marketingItems: [
      'Uncapped Actions',
      'SDR Agent',
      'No code Agent Studio - build all of your own Agents',
      'Build spells with our Research Agent and Bland Call Agent',
      'Advanced security and compliance controls, including SOC2',
      'Prompt Injection Prevention, PII & Sensitive Data, and Data Retention controls',
    ],
    highlighted: false,
  },
]);

const items = [
  {
    label: 'Monthly',
    value: 'monthly',
  },
  {
    label: 'Yearly',
    value: 'yearly',
  },
];
</script>
<template>
  <div class="flex flex-col gap-4 w-full items-start">
    <p class="main-title">All Plans</p>
    <UBadge color="primary" variant="subtle" size="lg">
      Save 20% annually
    </UBadge>
    <UTabs :items="items" class="mt-2">
      <template #item="{ item }">
        <div class="flex gap-4 mt-8 items-stretch">
          <BillingPricingCard
            v-for="plan in plans"
            :key="plan.type"
            :plan="plan"
            :term="item.value"
          />
        </div>
      </template>
    </UTabs>

    <p class="caption italic">
      *A task is a step run (excluding Start and Display steps), or a single
      agent task.
    </p>
  </div>
</template>
