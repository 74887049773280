<script setup lang="ts">
import { SubscriptionType } from '@respell/database';
import { subscriptionTypeToMonthlyActionLimit } from '@respell/utils/subscription';
import {
  stripeMonthlyTaskPriceId,
  stripeYearlyTaskPriceId,
  subscriptionTypeToIconName,
  taskCost,
} from '~/util/constants';
import { formatCurrency } from '~/util/formats';

const workspaceStore = useWorkspaceStore();
const { workspaceId } = storeToRefs(workspaceStore);

export interface BillingCard {
  type: SubscriptionType;
  isEnterprise: boolean;
}

const props = defineProps<{
  plan: BillingCard;
  term: string;
}>();
const { plan } = props;
const {
  name,
  type,
  min,
  isEnterprise,
  subscriptionPriceIds,
  subscriptionPricePerMonth,
  description,
  marketingTitle,
  marketingItems,
  highlighted,
} = plan;

const navigateToStripeSessionUrl = async () => {
  const sessionUrl = await $api(`/api/stripe/${workspaceId.value}/session`, {
    method: 'POST',
    body: {
      lineItems: lineItems.value,
      type: type,
    },
  });

  navigateTo(sessionUrl, { external: true });
};

const taskPrices = {
  monthly: stripeMonthlyTaskPriceId,
  yearly: stripeYearlyTaskPriceId,
};

const lineItems = computed(() => [
  // Main subscription plan
  {
    price: subscriptionPriceIds[props.term],
    quantity: 1,
  },
  // Additional tasks
  ...(sliderValue.value - monthlyRunLimit > 0
    ? [
        {
          price: taskPrices[props.term],
          quantity: sliderValue.value - monthlyRunLimit, // Don't need to include the base amount of tasks
        },
      ]
    : []),
]);

const cost = computed(
  () =>
    subscriptionPricePerMonth[props.term] +
    (sliderValue.value - monthlyRunLimit) * taskCost,
);
const monthlyRunLimit = subscriptionTypeToMonthlyActionLimit(
  type as SubscriptionType,
);

const sliderValue = ref(monthlyRunLimit);
</script>
<template>
  <UCard
    :ui="{
      header: {
        base: 'flex flex-col gap-2 items-start',
      },
      body: {
        base: 'flex flex-col gap-1 items-start',
        padding: '!py-0',
      },
      divide: 'divide-y-0',
    }"
    class="flex-1"
  >
    <template #header>
      <UBadge color="primary" variant="soft" size="sm">
        <UIcon
          :name="subscriptionTypeToIconName[type as SubscriptionType]"
          class="text-xl text-primary-500"
        />
        <p class="capitalize text-lg font-bold ml-2">{{ type }}</p>
      </UBadge>
      <p class="dimmed body-sm">{{ description }}</p>
    </template>

    <div v-if="!isEnterprise" class="contents">
      <p class="main-title">
        {{ formatCurrency(cost, 2) }}
        <span class="text-sm dimmed"> / per month </span>
      </p>
      <p class="caption">Billed {{ term }}</p>

      <span class="flex gap-1 py-2 items-baseline">
        <UBadge color="primary" variant="subtle" size="lg">
          {{ sliderValue }}</UBadge
        >
        <p class="caption">Tasks per month*</p>
      </span>

      <URange
        v-model="sliderValue"
        :min="monthlyRunLimit"
        :max="10000"
        :step="100"
        class="w-full mt-1"
      />
      <UButton
        :color="highlighted ? 'primary' : 'white'"
        block
        size="xl"
        class="my-4"
        @click="navigateToStripeSessionUrl"
      >
        Upgrade
      </UButton>
    </div>
    <div v-else class="contents">
      <p class="main-title">Let's talk!</p>
      <UButton
        color="white"
        block
        size="xl"
        to="mailto:sales@respell.ai"
        class="my-4"
      >
        Contact sales
      </UButton>
    </div>
    <template #footer>
      <p class="subtitle">
        {{ marketingTitle }}
      </p>
      <ul class="list-none mt-4">
        <li
          v-for="marketingItem in marketingItems"
          :key="marketingItem"
          class="flex gap-2 justify-start pb-2 items-start"
        >
          <UIcon
            name="i-ph-check-circle"
            class="text-lg shrink-0 text-primary-500"
          />
          <p class="text-sm dimmed">
            {{ marketingItem }}
          </p>
        </li>
      </ul>
    </template>
  </UCard>
</template>
