<script setup lang="ts">
import type { Member } from '@respell/database';
import BillingInsights from '~/components/account/BillingInsights.vue';
import BillingPricingTable from '~/components/account/BillingPricingTable.vue';
import ConfirmAddonTasksModal from '~/components/modals/ConfirmAddonTasksModal.vue';
import ManagePlanButton from '~/components/subscription/ManagePlanButton.vue';
import {
  stripeStarterMonthlyPriceId,
  stripeStarterYearlyPriceId,
  subscriptionTypeToDisplayName,
  subscriptionTypeToIconName,
  taskCost,
} from '~/util/constants';
import { formatCurrency } from '~/util/formats';

const modal = useModal();

const workspaceStore = useWorkspaceStore();
const {
  workspace,
  subscription,
  monthlyActions,
  monthlyActionLimit,
  monthlyAddonTasks,
  subscriptionType,
  workspaceMembers,
  workspaceId,
} = storeToRefs(workspaceStore);

await useAsyncCache('workspaceMembers', () =>
  workspaceStore.loadWorkspaceMembers(),
);

const { refresh } = useAsyncCache(
  `spellMeter/${workspaceId}`,
  () => workspaceStore.fetchSpellMeter(),
  {
    lazy: true,
  },
);

const toast = useToast();

const { data: stripeSession, pending } = await useAsyncData(
  'stripeSession',
  () => $api(`/api/stripe/${workspace.value.id}`),
  {
    watch: [workspace],
  },
);

const sliderValue = ref(monthlyAddonTasks.value);
const delta = computed(() => sliderValue.value - monthlyAddonTasks.value);
const costNumber = computed(() => delta.value * taskCost);
const cost = computed(() => formatCurrency(costNumber.value, 2));
const hasChanged = computed(() => !(delta.value === 0));

// Computation of whether they cannot reduce lower than used
const addonTasksUsed = computed(() =>
  Math.max(monthlyActions.value - monthlyActionLimit.value, 0),
);
const valueTooLow = computed(
  () => addonTasksUsed.value > 0 && addonTasksUsed.value + delta.value <= 0,
);

const interval = [
  stripeStarterYearlyPriceId,
  stripeStarterMonthlyPriceId,
].includes(subscription.stripePriceId)
  ? 'year'
  : 'month';

const workspaceAdmins = computed(() => {
  return workspaceMembers.value.filter(
    (member: Member) => member.role === 'admin',
  );
});

const uniqueAdmins = useArrayUnique(
  workspaceAdmins.value,
  (memberA: Member, memberB: Member) => memberA.userId === memberB.userId,
);

const uniqueUsers = useArrayUnique(
  workspaceMembers.value,
  (memberA: Member, memberB: Member) => memberA.userId === memberB.userId,
);

const isFree = computed(() => subscriptionType.value === 'free');
const isEnterprise = computed(() => subscriptionType.value === 'enterprise');

const handleTasks = async () => {
  modal.open(ConfirmAddonTasksModal, {
    action: delta.value >= 0 ? 'add' : 'remove',
    count: Math.abs(delta.value),
    cost: costNumber.value,
    workspaceName: workspace.value.name,
    interval: interval,
    onConfirm: updateSubscriptionItemQuantity,
  });
};

const isLoading = ref(false);

const updateSubscriptionItemQuantity = async () => {
  isLoading.value = true;

  const response = await $api(`/api/stripe/${workspaceId.value}/tasks`, {
    method: 'POST',
    body: {
      stripeSubscriptionId: subscription.value.stripeSubscriptionId,
      count: sliderValue.value,
    },
  });

  if (response.success) {
    refresh();
    toast.add({
      title: 'Successfully added tasks.',
      id: 'modal-success',
    });
  } else {
    toast.add({
      title: 'Something went wrong.',
      id: 'modal-failure',
      color: 'red',
    });
  }
  isLoading.value = false;
};
</script>
<template>
  <NuxtLayout name="account" :containerless="true">
    <p class="main-title mb-8">Plans & Billing</p>

    <!-- CURRENT PLAN -->
    <div class="border border-gray-200 rounded-xl">
      <div class="flex justify-between items-center p-4">
        <div>
          <p class="flex justify-start space-x-2">
            <span class="body-sm dimmed">Current plan:</span>
            <UBadge
              v-if="!isFree"
              size="sm"
              :ui="{
                rounded: 'rounded-full',
                base: 'gap-1',
                variant: {
                  solid: 'bg-gray-50 border border-gray-200 text-gray-600',
                },
              }"
            >
              <UIcon class="text-green-500" name="i-ph-circle-fill" />
              <span>Active</span>
            </UBadge>
          </p>

          <p class="heading-md font-bold mt-2 mb-2">
            <UIcon
              :name="subscriptionTypeToIconName[subscriptionType]"
              class="text-gray-500"
            />
            {{
              subscriptionTypeToDisplayName[subscriptionType] ||
              subscriptionTypeToDisplayName['free']
            }}
          </p>
        </div>
        <ManagePlanButton />
      </div>
      <hr />
      <div class="flex justify-between items-center p-4">
        <div>
          <div class="body-sm dimmed">Admin(s):</div>
          <div class="heading-md font-bold mt-2 mb-2">
            {{ uniqueAdmins.length }}
          </div>
        </div>
        <div>
          <div class="body-sm dimmed">Member(s):</div>
          <div class="heading-md font-bold mt-2 mb-2">
            {{ uniqueUsers.length }}
          </div>
        </div>
        <UButton
          variant="ghost"
          icon="i-ph-arrow-right"
          trailing
          size="xl"
          @click="navigateTo('/account/members')"
          >Manage Members
        </UButton>
      </div>
    </div>

    <!-- BUY MORE TASKS SLIDER -->
    <div v-if="!isEnterprise" class="border border-gray-200 rounded-xl mt-8">
      <div class="flex justify-between items-center p-4">
        <div class="w-full space-y-4">
          <p class="font-bold" :class="isFree ? 'dimmed' : ''">
            Add more tasks to your current subscription
          </p>
          <URange
            v-if="sliderValue || sliderValue === 0"
            :disabled="isFree"
            v-model="sliderValue"
            size="md"
            :min="0"
            :max="10000"
            :step="100"
            :color="valueTooLow && !isFree ? 'red' : 'primary'"
          />
          <p class="dimmed">Your plan limits with add-ons</p>
          <p class="flex justify-start space-x-4">
            <span class="text-xl font-bold text-primary-500">{{
              monthlyActionLimit
            }}</span>
            <UBadge
              :color="valueTooLow ? 'red' : 'primary'"
              variant="subtle"
              size="lg"
            >
              +{{ sliderValue }}
            </UBadge>
            <span class="font-bold body-lg" :class="isFree ? 'dimmed' : ''"
              >Tasks per month</span
            >
          </p>
        </div>
      </div>
      <hr />
      <div class="flex justify-start p-4 space-x-4">
        <UTooltip
          :prevent="!isFree"
          text="A starter or team plan is required buy more tasks."
        >
          <UTooltip
            :prevent="!valueTooLow"
            text="You have already used these tasks."
          >
            <UButton
              :loading="isLoading"
              :disabled="isFree || !hasChanged || valueTooLow"
              size="xl"
              @click="handleTasks"
              :color="valueTooLow && !isFree ? 'red' : 'primary'"
            >
              <p>{{ delta >= 0 ? 'Buy add-ons' : 'Remove add-ons' }}</p>
            </UButton>
          </UTooltip>
        </UTooltip>
        <p class="font-bold" :class="isFree ? 'dimmed' : ''">{{ cost }}</p>
        <p class="dimmed">extra / {{ interval }}</p>
      </div>
    </div>

    <UDivider class="my-8" />

    <!-- RESPELL PRICING TABLE -->
    <BillingPricingTable v-if="isFree" />

    <div v-else-if="isEnterprise" class="mt-8">
      <BillingInsights />
    </div>
  </NuxtLayout>
</template>
