import { SubscriptionType } from '@respell/database';

export const subscriptionTypeToMonthlyActionLimit = (
  subscriptionType: SubscriptionType,
): number | null => {
  switch (subscriptionType) {
    case SubscriptionType.free:
      return 125;
    case SubscriptionType.starter:
      return 750;
    case SubscriptionType.team:
      return 2500;
    case SubscriptionType.enterprise:
      return null;
    default:
      return 25;
  }
};
